.card{
    width: 25%;
    height: 320px;
    margin: 40px;
    border-radius: 10px;
    transition: all 0.4s ease;
}
.card .img_box{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
}
.card .img_box:hover img{
    transform: scale(1.1);
}
.card .img_box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: all 0.4s ease;
}
.card .tags{
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    margin-top: 10px;
}
.card .tags p{
    background: #7eedca;
    color: #383f51;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    cursor: default;
}
.card .tags .list{
    display: flex;
    justify-content: center;
}
.card .tags .list li{
    margin: 0 5px;
    padding: 5px 15px;
    border-radius: 5px;
    color: #7eedca;
    border: 2px solid #7eedca;
    font-size: 12px;
    cursor: default;
}
.card .tags .list li:nth-child(3){
    margin-right: 0;
}
@media (max-width: 1270px){
    .card{
        width: 300px;
    }
}
@media (max-width: 780px){
    .card{
        width: 100%;
        margin: 40px 0;
    }
}
@media (max-width: 350px){
    .card .tags .list{
        display: none;
    }
}