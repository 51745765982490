.cards{
    width: 100%;
    height: 100%;
    padding: 120px 50px 60px;
}
.cards h2{
    text-align: left;
    letter-spacing: 8px;
    color: rgba(255,255,255,0.8);
    font-size: 50px;
    position: relative;
    cursor: default;
    margin-left: 20px;
}
.cards h2::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 90px;
    border-bottom: 4px solid #00e6b4;
    border-radius: 3px;
    transition: all 0.4s ease;
}
.cards h2:hover::after{
    width: 160px;
}
.cards .container_categ{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 50px 0;
    height: 100%;
    flex-wrap: wrap;
}
.cards .container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 0;
    width: 100%;
    height: 100%;
}
@media (max-width: 900px){
    .cards{
        padding: 150px 20px 60px;
    }

}