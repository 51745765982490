.banner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 20px;
}
.banner .text_area{
    width: 650px;
    text-align: left;
}
.banner .btn{
    background: #00E6B4;
    color: #1d2026;
    font-weight: 600;
    padding: 10px 25px;
    display: inline-block;
    transition: all 0.4s ease;
    border-radius: 5px;
    margin-top: 50px;
}
.banner h1{
    color: white;
    font-size: 70px;
    text-align: left;
}
.banner h1 span{
    color: #00E6B4;
}
.banner p{
    color: rgba(255,255,255,0.8);
    text-align: left;
    font-size: 20px;
    margin: 30px 0;
}
.banner p span{
    color: #00E6B4;
}
.banner .imgbox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 750px;
    margin: 20px 0 40px;
}
.banner .imgbox img{
    width: 100%;
}
@media screen and (max-width: 1450px){
    .banner {
        flex-direction: column;
        margin-top: 150px;
    }
    .banner .text_area{
        text-align: center;
        width: 100%;
        padding: 0 50px;
    }
    .banner p, .banner h1{
        text-align: center;
        width: 100%;
    }
}
@media screen and (max-width: 850px){
    .banner .imgbox{
        width: 100%;
    }
}
@media screen and (max-width: 660px){
    .banner .imgbox{
       display: none;
    }
    .banner h1{
        font-size: 50px;
    }
    .banner {
        flex-direction: column;
        margin-top: 0;
    }
    .banner .text_area{
        padding: 0 20px;
    }
}
@media screen and (max-width: 380px){
    .banner h1{
        font-size: 40px;
    }
}