.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 20px 50px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    position: absolute;
    top: 0;
    left: 0;
}
.header .title{
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.header .title span{
    color: #00E6B4;
}
.header .button{
    padding: 10px 15px;
    display: inline-block;
    border: 2px solid #00E6B4;
    border-radius: 5px;
    color: #00E6B4;
    font-size: 12px;
    transition: all 0.4s ease;
    text-align: center;
    font-weight: 500;
}
.header .button:hover{
    background: #00E6B4;
    color: #1d2026;
}
@media screen and (max-width: 800px){
    .header{
        padding: 20px 15px;
    }
    .header .button{
        padding: 10px 15px;
        font-size: 12px;
    }
    .header .title{
        color: white;
        font-size: 17px;
        font-weight: bold;
    }
}
@media screen and (max-width: 436px){
    .header .button{
        display: none;
    }
}