@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    text-decoration: none;
    list-style: none;
    font-family: 'Poppins', sans-serif;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #00E6B4 #282c34;
}

/* Marche sur Chrome, Edge, et Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: #282c34;
}

*::-webkit-scrollbar-thumb {
    background-color: #00E6B4;
    border-radius: 20px;
}
body{
    background: #212121;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
}
.buttonCategories{
    right: 40px;
    bottom: 40px;
    position: fixed;
    background: #00E6B4;
    color: white;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all 0.4s ease;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.buttonCategories:hover{
    -webkit-transform: scale(0.80);
            transform: scale(0.80);
}
.buttonCategories p{
    color: #1d2026;
    font-weight: 800;
    font-size: 14px;
}
.buttonCategories::after{
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: #00E6B4 3px solid;
    opacity: 0.6;
    -webkit-animation: pulse2 2s ease infinite;
            animation: pulse2 2s ease infinite;
}
.buttonCategories::before{
    content: '';
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: #00E6B4 3px solid;
    opacity: 0.4;
    -webkit-animation: pulse 2s ease-in-out infinite;
            animation: pulse 2s ease-in-out infinite;
}
@-webkit-keyframes pulse{
    0%{
        opacity: 0.4;
    }
    10%{
        opacity: 0.3;
    }
    20%{
        opacity: 0.2;
    }
    30%{
        opacity: 0.1;
    }
    40%{
        opacity: 0;
    }
    50%{
        opacity: 0.1;
    }
    60%{
        opacity: 0.2;
    }
    70%{
        opacity: 0.3;
    }
    80%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.4;
    }
}
@keyframes pulse{
    0%{
        opacity: 0.4;
    }
    10%{
        opacity: 0.3;
    }
    20%{
        opacity: 0.2;
    }
    30%{
        opacity: 0.1;
    }
    40%{
        opacity: 0;
    }
    50%{
        opacity: 0.1;
    }
    60%{
        opacity: 0.2;
    }
    70%{
        opacity: 0.3;
    }
    80%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.4;
    }
}
@-webkit-keyframes pulse2{
    0%{
        opacity: 0.4;
    }
    10%{
        opacity: 0.3;
    }
    20%{
        opacity: 0.2;
    }
    30%{
        opacity: 0.1;
    }
    40%{
        opacity: 0;
    }
    50%{
        opacity: 0.1;
    }
    60%{
        opacity: 0.2;
    }
    70%{
        opacity: 0.3;
    }
    80%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.4;
    }
}
@keyframes pulse2{
    0%{
        opacity: 0.4;
    }
    10%{
        opacity: 0.3;
    }
    20%{
        opacity: 0.2;
    }
    30%{
        opacity: 0.1;
    }
    40%{
        opacity: 0;
    }
    50%{
        opacity: 0.1;
    }
    60%{
        opacity: 0.2;
    }
    70%{
        opacity: 0.3;
    }
    80%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.4;
    }
}
.cards{
    width: 100%;
    height: 100%;
    padding: 120px 50px 60px;
}
.cards h2{
    text-align: left;
    letter-spacing: 8px;
    color: rgba(255,255,255,0.8);
    font-size: 50px;
    position: relative;
    cursor: default;
    margin-left: 20px;
}
.cards h2::after{
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 90px;
    border-bottom: 4px solid #00e6b4;
    border-radius: 3px;
    transition: all 0.4s ease;
}
.cards h2:hover::after{
    width: 160px;
}
.cards .container_categ{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 50px 0;
    height: 100%;
    flex-wrap: wrap;
}
.cards .container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 50px 0;
    width: 100%;
    height: 100%;
}
@media (max-width: 900px){
    .cards{
        padding: 150px 20px 60px;
    }

}
.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-height: 40px;
    padding: 20px 50px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    position: absolute;
    top: 0;
    left: 0;
}
.header .title{
    color: white;
    font-size: 20px;
    font-weight: bold;
}
.header .title span{
    color: #00E6B4;
}
.header .button{
    padding: 10px 15px;
    display: inline-block;
    border: 2px solid #00E6B4;
    border-radius: 5px;
    color: #00E6B4;
    font-size: 12px;
    transition: all 0.4s ease;
    text-align: center;
    font-weight: 500;
}
.header .button:hover{
    background: #00E6B4;
    color: #1d2026;
}
@media screen and (max-width: 800px){
    .header{
        padding: 20px 15px;
    }
    .header .button{
        padding: 10px 15px;
        font-size: 12px;
    }
    .header .title{
        color: white;
        font-size: 17px;
        font-weight: bold;
    }
}
@media screen and (max-width: 436px){
    .header .button{
        display: none;
    }
}
.categorie_cards{
    width: 350px;
    height: 120px;
    border-radius: 15px;
    background: #222222;
    border: 2px solid #00E6B4;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    /*border-bottom: #00E6B4 2px solid;*/
    font-size: 20px;
    color: white;
}
.categorie_cards:hover{
    -webkit-transform: scale(0.85);
            transform: scale(0.85);
    color: #00E6B4;
}
@media (max-width: 830px){
    .categorie_cards{
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
        border: 2px solid #00E6B4;
        margin: 20px 0;
    }

}
.banner{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    padding: 0 20px;
}
.banner .text_area{
    width: 650px;
    text-align: left;
}
.banner .btn{
    background: #00E6B4;
    color: #1d2026;
    font-weight: 600;
    padding: 10px 25px;
    display: inline-block;
    transition: all 0.4s ease;
    border-radius: 5px;
    margin-top: 50px;
}
.banner h1{
    color: white;
    font-size: 70px;
    text-align: left;
}
.banner h1 span{
    color: #00E6B4;
}
.banner p{
    color: rgba(255,255,255,0.8);
    text-align: left;
    font-size: 20px;
    margin: 30px 0;
}
.banner p span{
    color: #00E6B4;
}
.banner .imgbox{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 750px;
    margin: 20px 0 40px;
}
.banner .imgbox img{
    width: 100%;
}
@media screen and (max-width: 1450px){
    .banner {
        flex-direction: column;
        margin-top: 150px;
    }
    .banner .text_area{
        text-align: center;
        width: 100%;
        padding: 0 50px;
    }
    .banner p, .banner h1{
        text-align: center;
        width: 100%;
    }
}
@media screen and (max-width: 850px){
    .banner .imgbox{
        width: 100%;
    }
}
@media screen and (max-width: 660px){
    .banner .imgbox{
       display: none;
    }
    .banner h1{
        font-size: 50px;
    }
    .banner {
        flex-direction: column;
        margin-top: 0;
    }
    .banner .text_area{
        padding: 0 20px;
    }
}
@media screen and (max-width: 380px){
    .banner h1{
        font-size: 40px;
    }
}
.card{
    width: 25%;
    height: 320px;
    margin: 40px;
    border-radius: 10px;
    transition: all 0.4s ease;
}
.card .img_box{
    width: 100%;
    height: 250px;
    border-radius: 10px;
    overflow: hidden;
}
.card .img_box:hover img{
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
}
.card .img_box img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: all 0.4s ease;
}
.card .tags{
    display: flex;
    justify-content: space-between;
    height: 50px;
    align-items: center;
    margin-top: 10px;
}
.card .tags p{
    background: #7eedca;
    color: #383f51;
    font-weight: bold;
    font-size: 15px;
    padding: 5px 15px;
    border-radius: 5px;
    text-align: center;
    cursor: default;
}
.card .tags .list{
    display: flex;
    justify-content: center;
}
.card .tags .list li{
    margin: 0 5px;
    padding: 5px 15px;
    border-radius: 5px;
    color: #7eedca;
    border: 2px solid #7eedca;
    font-size: 12px;
    cursor: default;
}
.card .tags .list li:nth-child(3){
    margin-right: 0;
}
@media (max-width: 1270px){
    .card{
        width: 300px;
    }
}
@media (max-width: 780px){
    .card{
        width: 100%;
        margin: 40px 0;
    }
}
@media (max-width: 350px){
    .card .tags .list{
        display: none;
    }
}
