.categorie_cards{
    width: 350px;
    height: 120px;
    border-radius: 15px;
    background: #222222;
    border: 2px solid #00E6B4;
    margin: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.4s ease;
    /*border-bottom: #00E6B4 2px solid;*/
    font-size: 20px;
    color: white;
}
.categorie_cards:hover{
    transform: scale(0.85);
    color: #00E6B4;
}
@media (max-width: 830px){
    .categorie_cards{
        width: 100%;
        box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
        border: 2px solid #00E6B4;
        margin: 20px 0;
    }

}