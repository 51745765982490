@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    text-decoration: none;
    list-style: none;
    font-family: 'Poppins', sans-serif;
}
* {
    scrollbar-width: thin;
    scrollbar-color: #00E6B4 #282c34;
}

/* Marche sur Chrome, Edge, et Safari */
*::-webkit-scrollbar {
    width: 6px;
}

*::-webkit-scrollbar-track {
    background: #282c34;
}

*::-webkit-scrollbar-thumb {
    background-color: #00E6B4;
    border-radius: 20px;
}
body{
    background: #212121;
    display: flex;
    justify-content: center;
    min-height: 100vh;
    align-items: center;
}
.buttonCategories{
    right: 40px;
    bottom: 40px;
    position: fixed;
    background: #00E6B4;
    color: white;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all 0.4s ease;
    transform: scale(0.9);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px;
}
.buttonCategories:hover{
    transform: scale(0.80);
}
.buttonCategories p{
    color: #1d2026;
    font-weight: 800;
    font-size: 14px;
}
.buttonCategories::after{
    content: '';
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: #00E6B4 3px solid;
    opacity: 0.6;
    animation: pulse2 2s ease infinite;
}
.buttonCategories::before{
    content: '';
    position: absolute;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: #00E6B4 3px solid;
    opacity: 0.4;
    animation: pulse 2s ease-in-out infinite;
}
@keyframes pulse{
    0%{
        opacity: 0.4;
    }
    10%{
        opacity: 0.3;
    }
    20%{
        opacity: 0.2;
    }
    30%{
        opacity: 0.1;
    }
    40%{
        opacity: 0;
    }
    50%{
        opacity: 0.1;
    }
    60%{
        opacity: 0.2;
    }
    70%{
        opacity: 0.3;
    }
    80%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.4;
    }
}
@keyframes pulse2{
    0%{
        opacity: 0.4;
    }
    10%{
        opacity: 0.3;
    }
    20%{
        opacity: 0.2;
    }
    30%{
        opacity: 0.1;
    }
    40%{
        opacity: 0;
    }
    50%{
        opacity: 0.1;
    }
    60%{
        opacity: 0.2;
    }
    70%{
        opacity: 0.3;
    }
    80%{
        opacity: 0.4;
    }
    100%{
        opacity: 0.4;
    }
}